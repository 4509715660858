import { roboto as theme } from "@theme-ui/presets"

export default {
  ...theme,
  lineHeights: {
    body: 1.6,
    heading: 1.3
  },
  fonts: {
    heading: "Open Sans, Helvetica, Arial, sans-serif",
    body: "Lora, serif"
  },
  colors: {
    primary: "#666"
  },
  styles: {
    ...theme.styles,
    hr: {
      border: 0
    },
    pre: {
      marginLeft: '-16px',
      marginRight: '-16px',
      borderRadius: '0px'
    },
  }
}
